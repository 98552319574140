































import _ from 'lodash'
import cssesc from 'cssesc'
import { Carousel, Slide } from 'vue-carousel';

export default
  data: ->
    navigateTo: 0
    brandData: null
    count: 0

  computed:
    brandGroups: ->
      [chunks, limit] = switch @.$mq
        when 'small' then [2, 10]
        when 'medium' then [3, null]
        when 'large' then [6, null]
        when 'xlarge' then [6, null]
      chunked = _.chunk @.brandData, chunks
      if limit then chunked.slice(0, limit) else chunked

  methods:
    allBrandsPath: ->
      @.$app.brands_path(@.$i18n.locale)
    backgroundStyle: (brand) ->
      backgroundImage: "url(#{cssesc(brand.image, wrap: true)})" if brand.image
    getBrandData: ->
      $.get @.$app.top_brands_path(@.$i18n.locale, format: 'json'), (response) =>
        @.brandData = response.data
        @.count = response.count
    usedBrandForSalesPath: (brand) ->
      @.$app.used_brand_for_sales_path(@.$i18n.locale, id: brand.friendly_id)

  created: ->
    @.getBrandData()

  # HACK: force window resize to fix Safari rendering issue
  updated: ->
    window.dispatchEvent(new Event('resize'))

  components: { Carousel, Slide }
