import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use VueI18n

i18n = new VueI18n
  # dateTimeFormats: window.dateTimeFormats
  fallbackLocale: 'en'
  locale: window.locale
  messages: window.translations
  # numberFormats:
  #   "#{window.locale}":
  #     currency:
  #       style: 'currency'
  #       currency: window.currencyCode

export default i18n
