



































import _ from 'lodash'
import { Carousel, Slide } from 'vue-carousel';

export default
  data: ->
    wanteds: []
    count: 0

  computed:
    buttonClasses: ->
      if @.$mq == 'small'
        'mobile-button wanteds hide-for-medium section-margin'
    wantedGroups: ->
      [chunks, limit] = switch @.$mq
        when 'small' then [1, 10]
        when 'medium' then [2, null]
        when 'large' then [3, null]
        when 'xlarge' then [3, null]
      chunked = _.chunk @.wanteds, chunks
      if limit then chunked.slice(0, limit) else chunked

  methods:
    allWantedsPath: ->
      @.$app.wanteds_path(@.$i18n.locale)
    getWanteds: ->
      $.get @.$app.public_api_wanteds_path(@.$i18n.locale), (response) =>
        @.wanteds = response.data
        @.count = response.count
    recentWantedPath: ->
      @.$app.recent_wanteds_path(@.$i18n.locale)
    postFreeWanted: ->
      @.$app.new_wanted_path(@.$i18n.locale)
    styleFor: (wanted) ->
      "background-image: url('#{wanted.relevant_thumb}')" if wanted.relevant_thumb
    wantedPath: (wanted) ->
      @.$app.wanted_path(@.$i18n.locale, id: wanted.friendly_id)

  created: ->
    @.getWanteds()

  components: { Carousel, Slide }
