
timeagoLocales = {}

for mmLocale, dateFnsLocale of window.dateFnsCrossReference
  timeagoLocales[mmLocale] = require("date-fns/locale/#{dateFnsLocale}/index.js")

# 3rd-Party Libraries

import _ from 'lodash'
# import 'core-js/stable'
# import 'regenerator-runtime/runtime'
import Vue from 'vue'

Vue.config.productionTip = false

# Localization
import i18n from 'src/mt/i18n'

import VueTimeago from 'vue-timeago'

# MT Global Components

import Auctions from 'src/mt/components/auctions'
import ForSales from 'src/mt/components/for-sales'
import TopBrands from 'src/mt/components/top-brands'
import TopTypes from 'src/mt/components/top-types'
import Wanteds from 'src/mt/components/wanteds'
import RecommendedListings from 'src/mt/components/recommended-listings'

# Rails Routes

import routes from 'src/mt/rails-routes'
Vue.prototype.$app = routes

# Media Queries

import VueMq from 'vue-mq'
Vue.use VueMq,
  breakpoints:
    small: 730
    medium: 1024
    large: 1200
    xlarge: 1500

# Build Vue Instance

$(document).ready ->
  Vue.use VueTimeago,
    locale: window.locale
    locales: timeagoLocales

  if $('.top-types-component').length > 0
    new Vue
      components: { TopTypes }
      el: '.top-types-component'
      i18n: i18n
      template: '<TopTypes/>'

  $(document).on 'open.zf.reveal', '.request-sent-modal', =>
    $component = $('.recommended-listings-component')
    if $component.length > 0
      data =
        'listing-id': $component.data('listing-id')
        'bt-subject': $component.data('bt-subject')
        financing: $component.data('financing')
        freight: $component.data('freight')
        'price-range': $component.data('price-range')
        'time-frame': $component.data('time-frame')
        'request-id': $component.data('request-id')
        'request-type-id': $component.data('request-type-id')
        'request-type-name': $component.data('request-type-name')
        'request-model': $component.data('request-model')
        'request-manufacturer': $component.data('request-manufacturer')
        reason: $component.data('reason')

      dataAttributes = _.pickBy data

      dataString = _.map dataAttributes, (val, key) -> "#{key}=\"#{val}\""
      dataString = dataString.join(' ')

      new Vue
        components: { RecommendedListings }
        el: '.recommended-listings-component'
        i18n: i18n
        template: "<RecommendedListings #{dataString} />"

  if $('.top-brands-component').length > 0
    new Vue
      components: { TopBrands }
      el: '.top-brands-component'
      i18n: i18n
      template: '<TopBrands/>'

  if $('.wanteds-component').length > 0
    new Vue
      components: { Wanteds }
      el: '.wanteds-component'
      i18n: i18n
      template: '<Wanteds/>'

  if $('.auctions-component').length > 0
    new Vue
      components: { Auctions }
      el: '.auctions-component'
      i18n: i18n
      template: '<Auctions/>'

  if $('.for-sales-component').length > 0
    new Vue
      components: { ForSales }
      el: '.for-sales-component'
      i18n: i18n
      template: '<ForSales/>'
