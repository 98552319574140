


























import _ from 'lodash'
import cssesc from 'cssesc'
import { Carousel, Slide } from 'vue-carousel';

export default
  data: ->
    allTabID: null
    navigateTo: 0
    selectedTabID: null
    typeData: null
    count: 0

  computed:
    selectedType: ->
      @.typeData[@.selectedTabID]
    tabs: ->
      Object.keys(@.typeData)
    typeGroups: ->
      [chunks, limit] = switch @.$mq
        when 'small' then [1, 10]
        when 'medium' then [4, null]
        when 'large' then [6, null]
        when 'xlarge' then [6, null]
      chunked = _.chunk @.selectedType.types, chunks
      if limit then chunked.slice(0, limit) else chunked

  methods:
    backgroundStyle: (type) ->
      backgroundImage: "url(#{cssesc(type.image, wrap: true)})" if type.image
    getTypeData: ->
      $.get @.$app.top_types_path(@.$i18n.locale, format: 'json'), (response) =>
        @.allTabID = Object.keys(response.data)[0]
        @.selectedTabID = @.allTabID
        @.typeData = response.data
        @.count = response.count
    resetNavigation: ->
      @.navigateTo = [false, 0]
    typePath: (type) ->
      @.$app.machinery_path(@.$i18n.locale, id: type.friendly_id)
    viewAllPath: ->
      anchor = @.selectedType.name unless @.selectedTabID == @.allTabID
      @.$app.machinery_index_path({anchor})

  # HACK: force window resize to fix Safari rendering issue
  updated: ->
    window.dispatchEvent(new Event('resize'))

  created: ->
    @.getTypeData()

  components: { Carousel, Slide }
