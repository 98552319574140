







































import _ from 'lodash'
import cssesc from 'cssesc'
import { Carousel, Slide } from 'vue-carousel';

export default
  data: ->
    forSales: []
    count: 0

  computed:
    forSaleGroups: ->
      [chunks, limit] = switch @.$mq
        when 'small' then [1, 10]
        when 'medium' then [2, null]
        when 'large' then [3, null]
        when 'xlarge' then [3, null]
      chunked = _.chunk @.forSales, chunks
      if limit then chunked.slice(0, limit) else chunked

  methods:
    backgroundStyle: (forSale) ->
      backgroundImage: "url(#{cssesc(forSale.image, wrap: true)})"
    detailsLine: (forSale) ->
      _.compact([forSale.condition, forSale.year, forSale.location]).join(' | ')
    getForSales: ->
      $.get @.$app.public_api_for_sales_path(@.$i18n.locale), (response) =>
        @.forSales = response.data
        @.count = response.count
    forSalePath: (forSale) ->
      @.$app.for_sale_path(@.$i18n.locale, id: forSale.friendly_id)
    viewAllPath: ->
      @.$app.recent_for_sales_path(@.$i18n.locale, 'machines') # ItemClass::MACHINES

  created: ->
    @.getForSales()

  components: { Carousel, Slide }
