

















































import cssesc from 'cssesc'
import _ from 'lodash'
import { Carousel, Slide } from 'vue-carousel';

export default
  data: ->
    auctions: []
    count: 0
    strings:
      ends: "Ends "

  computed:
    auctionGroups: ->
      [chunks, limit] = switch @.$mq
        when 'small' then [1, 10]
        when 'medium' then [2, null]
        when 'large' then [3, null]
        when 'xlarge' then [3, null]
      chunked = _.chunk @.auctions, chunks
      if limit then chunked.slice(0, limit) else chunked

  methods:
    allAuctionsPath: ->
      @.$app.auctions_path(@.$i18n.locale)
    auctionPath: (auction) ->
      @.$app.auction_path(@.$i18n.locale, id: auction.friendly_id)
    backgroundStyle: (auction) ->
      if auction.listing_image?
        backgroundImage: "url(#{cssesc(auction.listing_image, wrap: true)})"
      else if auction.company_logo
        backgroundImage: "url(#{cssesc(auction.company_logo, wrap: true)})"
      else
        # To be provided by Tim at a later time
    getAuctions: ->
      $.get @.$app.public_api_auctions_path(@.$i18n.locale), (response) =>
        @.auctions = response.data
        @.count = response.count
    imageClass: (auction) ->
      if auction.listing_image?
        'listing'
      else if auction.company_logo?
        'logo'
      else
        'placeholder'

  created: ->
    @.getAuctions()

  components: { Carousel, Slide }
